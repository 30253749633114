// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: rgb(103, 58, 183);
  --secondary: rgb(240, 235, 248);
  --focus: #4285f4;
  --button-gray: #ececec;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

body {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 80px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222a35;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575c66;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #626970;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,4BAA4B;EAC5B,+BAA+B;EAC/B,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;;AAEA,UAAU;AACV;EACE,UAAU;EACV,YAAY;AACd;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,oBAAoB;EACpB,aAAa;AACf","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap\");\n\n:root {\n  --primary: rgb(103, 58, 183);\n  --secondary: rgb(240, 235, 248);\n  --focus: #4285f4;\n  --button-gray: #ececec;\n}\n\n*,\n*:after,\n*:before {\n  box-sizing: border-box;\n}\n\nhtml {\n  scroll-behavior: smooth;\n  scroll-padding-top: 80px;\n}\n\nbody {\n  font-family: \"Noto Sans\", sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: white;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 4px;\n  height: 80px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #222a35;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #575c66;\n  border-radius: 6px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #626970;\n}\n\nselect {\n  appearance: none;\n  background-color: transparent;\n  border: none;\n  padding: 0 1em 0 0;\n  margin: 0;\n  width: 100%;\n  font-family: inherit;\n  font-size: inherit;\n  cursor: inherit;\n  line-height: inherit;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
